import React from "react";
import "./Project.css";
const Projects = () => {

    return (
        <div>
            Projects
        </div>
       
    )
};

export default Projects;